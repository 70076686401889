<template>
  <div id="qp-modal__overlay" class="pay-in-n-modal">
    <div class="modal-container" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <button
            @click="closeModal"
            class="close-button"
            aria-label="Close Zip buy now pay later informational popup"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5174_19759)">
                <path
                  d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                  fill="white"
                />
                <path
                  d="M17.25 7.8075L16.1925 6.75L12 10.9425L7.8075 6.75L6.75 7.8075L10.9425 12L6.75 16.1925L7.8075 17.25L12 13.0575L16.1925 17.25L17.25 16.1925L13.0575 12L17.25 7.8075Z"
                  fill="#1A0826"
                />
              </g>
              <defs>
                <clipPath id="clip0_5174_19759">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <!-- Zip Logo -->
          <svg
            width="87"
            height="30"
            viewBox="0 0 87 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3512_10446)">
              <path
                d="M1.77729 24.2319L2.47044 29.8594H26.8016L26.0078 23.4171H14.6804L14.5797 22.6199L25.0301 15.3628L24.331 9.72949H0L0.793855 16.1718H12.1626L12.2633 16.9748L1.77729 24.2319Z"
                fill="#1A0826"
              />
              <path
                d="M27.1635 9.72949L29.6516 29.8594H54.0004L51.5418 9.72949H27.1635Z"
                fill="#AA8FFF"
              />
              <path
                d="M79.5399 16.9925C78.9473 12.4693 75.3928 9.72945 70.5347 9.74716H54.3376L56.826 29.8772H64.1129L63.6153 25.85H71.3168C77.3953 25.85 80.1737 22.0767 79.5399 16.9925ZM70.5408 20.2048H62.9221L62.3297 15.3746H69.9897C71.7671 15.3746 72.715 16.402 72.8632 17.7838C72.9089 18.1018 72.8815 18.426 72.7827 18.7319C72.684 19.0377 72.5166 19.317 72.2931 19.5487C72.0698 19.7804 71.7966 19.9583 71.4939 20.069C71.1913 20.1796 70.939 20.2048 70.5408 20.2048Z"
                fill="#1A0826"
              />
              <path
                d="M34.5856 7.13723C35.8712 5.77321 35.6581 3.4939 34.1058 2.04129C32.5536 0.588674 30.2432 0.517822 28.9516 1.88186C27.6601 3.2459 27.8794 5.5311 29.4316 6.98372C30.9838 8.43634 33.2942 8.50128 34.5856 7.13723Z"
                fill="#1A0826"
              />
              <path
                d="M82.7447 12.3099H82.259L81.9924 10.1487H81.2816L81.2281 9.72949H83.1654L83.2186 10.1487H82.4783L82.7447 12.3099Z"
                fill="#1A0826"
              />
              <path
                d="M84.4268 12.3099H83.9706L83.6566 9.72949H84.1247L85.0133 10.7688L85.6532 9.72949H86.0915L86.4114 12.3099H85.9553L85.7243 10.4145L85.6591 10.5385L85.12 11.4183H85.0489L84.2964 10.5385L84.2194 10.4322L84.4268 12.3099Z"
                fill="#1A0826"
              />
            </g>
            <defs>
              <clipPath id="clip0_3512_10446">
                <rect width="86.8421" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h2 class="modal-title">Shop now, pay over time</h2>
        </div>
        <div class="modal-body">
          <div class="payment-schedule-container">
            <div class="payment-schedule">
              <div
                class="accordion-visible"
                @click="toggleAccordion(ScheduleType.payin4)"
                :class="{
                  'payment-schedule-disabled': !isPayIn4Available,
                }"
              >
                <div class="accordion-left" v-if="isPayIn4Available">
                  <h3>
                    4 payments of {{ formatPrice(payIn4InstallmentPrice) }}
                    <sup>1</sup>
                  </h3>
                  <p v-if="payIn4InstallmentInterval">
                    Pay {{ payIn4InstallmentInterval }} over
                    {{ payIn4Weeks }} weeks
                  </p>
                </div>
                <div class="accordion-left" v-else>
                  <h3>4 bi-weekly payments <sup>1</sup></h3>
                  <p v-if="isPayIn4UnderMin">
                    Available on orders over {{ formatPrice(payIn4Min) }}
                  </p>
                  <p v-else-if="isPayIn4OverMax">
                    Available on orders under {{ formatPrice(payIn4Max) }}
                  </p>
                  <p v-else>
                    Not available on this order
                  </p>
                </div>
                <div v-if="isPayIn4Available" class="accordion-right">
                  <!-- Caret Up -->
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :style="
                      !paymentScheduleAccordionVisibility[ScheduleType.payin4]
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    "
                  >
                    <path
                      d="M3 10L8 5L13 10"
                      stroke="#1A0826"
                      stroke-width="1.5"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-if="isPayIn4Available"
                v-show="paymentScheduleAccordionVisibility[ScheduleType.payin4]"
                class="accordion-hidden"
              >
                <div class="installment-graphic-container">
                  <div
                    class="installment"
                    v-for="(installment, index) in payIn4Installments"
                    :key="installment.date"
                  >
                    <div class="donut-icon">
                      <svg
                        height="42"
                        width="42"
                        view-box="0 0 42 42"
                        role="img"
                      >
                        <title>
                          A donut representing installment {sequence} of {total}
                          for the current payment plan.
                        </title>
                        <circle
                          cx="21"
                          cy="21"
                          r="15.9154943092"
                          stroke-width="5.5"
                          stroke="#E2E1DF"
                          fill="none"
                        ></circle>
                        <circle
                          class="donut-path"
                          cx="21"
                          cy="21"
                          r="15.9154943092"
                          stroke-width="5.5"
                          stroke="#AA8FFF"
                          fill="none"
                          :stroke-dasharray="
                            `${(100 * (index + 1)) / 4} ${100 -
                              (100 * (index + 1)) / 4}`
                          "
                          stroke-dashoffset="25"
                        ></circle>
                      </svg>
                    </div>
                    <div class="content">
                      <div class="price" :class="{ first: index === 0 }">
                        {{ formatPrice(installment.totalAmountIncludingFees) }}
                      </div>
                      <div class="due-date" :class="{ first: index === 0 }">
                        {{ formatDate(installment) }}
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="payIn4APR" class="fee-subtext">
                  Includes fee:
                  {{ formatPrice(payIn4FinanceChargePerInstallment) }} per
                  installment ({{ formatPrice(payIn4FinanceCharge) }} per order
                  ({{ formatPercent(payIn4APR) }}
                  APR))
                </p>
                <p v-else-if="payIn4FinanceCharge" class="fee-subtext">
                  Includes finance charge:
                  {{ formatPrice(payIn4FinanceChargePerInstallment) }} per
                  installment ({{ formatPrice(payIn4FinanceCharge) }}
                  per order)
                </p>
              </div>
            </div>
            <div class="payment-schedule">
              <div
                class="accordion-visible"
                @click="toggleAccordion(ScheduleType.payin8)"
                :class="{
                  'payment-schedule-disabled': !isPayIn8Available,
                }"
              >
                <div class="accordion-left" v-if="isPayIn8Available">
                  <h3>
                    8 payments of {{ formatPrice(payIn8InstallmentPrice) }}
                    <sup>2</sup>
                  </h3>
                  <p v-if="payIn8InstallmentInterval">
                    Pay {{ payIn8InstallmentInterval }} over
                    {{ payIn8Weeks }} weeks
                  </p>
                </div>
                <div class="accordion-left" v-else>
                  <h3>8 bi-weekly payments <sup>2</sup></h3>
                  <p v-if="isPayIn8UnderMin">
                    Available on orders over {{ formatPrice(payIn8Min) }}
                  </p>
                  <p v-else-if="isPayIn8OverMax">
                    Available on orders under {{ formatPrice(payIn8Max) }}
                  </p>
                  <p v-else>
                    Not available on this order
                  </p>
                </div>
                <div v-if="isPayIn8Available" class="accordion-right">
                  <!-- Caret Up -->
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :style="
                      !paymentScheduleAccordionVisibility[ScheduleType.payin8]
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {}
                    "
                  >
                    <path
                      d="M3 10L8 5L13 10"
                      stroke="#1A0826"
                      stroke-width="1.5"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-if="isPayIn8Available"
                v-show="paymentScheduleAccordionVisibility[ScheduleType.payin8]"
                class="accordion-hidden"
              >
                <div class="installment-graphic-container">
                  <div
                    class="installment"
                    v-for="(installment, index) in payIn8Installments"
                    :key="installment.date"
                  >
                    <div class="donut-icon">
                      <svg
                        height="42"
                        width="42"
                        view-box="0 0 42 42"
                        role="img"
                      >
                        <title>
                          A donut representing installment {sequence} of {total}
                          for the current payment plan.
                        </title>
                        <circle
                          cx="21"
                          cy="21"
                          r="15.9154943092"
                          stroke-width="5.5"
                          stroke="#E2E1DF"
                          fill="none"
                        ></circle>
                        <circle
                          class="donut-path"
                          cx="21"
                          cy="21"
                          r="15.9154943092"
                          stroke-width="5.5"
                          stroke="#AA8FFF"
                          fill="none"
                          :stroke-dasharray="
                            `${(100 * (index + 1)) / 8} ${100 -
                              (100 * (index + 1)) / 8}`
                          "
                          stroke-dashoffset="25"
                        ></circle>
                      </svg>
                    </div>
                    <div class="content">
                      <div class="price" :class="{ first: index === 0 }">
                        {{ formatPrice(installment.totalAmountIncludingFees) }}
                      </div>
                      <div class="due-date" :class="{ first: index === 0 }">
                        {{ formatDate(installment) }}
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="payIn8FinanceCharge" class="fee-subtext">
                  Includes fee:
                  {{ formatPrice(payIn8FinanceChargePerInstallment) }} per
                  installment ({{ formatPrice(payIn8FinanceCharge) }} per order
                  ({{ formatPercent(payin8APR) }}
                  APR))
                </p>
              </div>
            </div>
          </div>
          <div class="features-container">
            <div class="feature-row">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18V6C21.75 5.58579 21.4142 5.25 21 5.25Z"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.7495 15.75H18.7495"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.2495 15.75H12.7495"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.24945 9.0799H21.7494"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>No impact to your credit score</p>
            </div>
            <div class="feature-row">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 10.75V5.25C3.75 5.05109 3.82902 4.86032 3.96967 4.71967C4.11032 4.57902 4.30109 4.5 4.5 4.5H19.5C19.6989 4.5 19.8897 4.57902 20.0303 4.71967C20.171 4.86032 20.25 5.05109 20.25 5.25V10.75C20.25 18.6264 13.5651 21.236 12.2303 21.6785C12.0811 21.7298 11.9189 21.7298 11.7697 21.6785C10.4349 21.236 3.75 18.6264 3.75 10.75Z"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.125 9.75L10.625 15L7.875 12.375"
                  stroke="#1A0826"
                  stroke-width="1.5"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Safe and secure checkout</p>
            </div>
            <a
              href="https://zip.co/us/how-it-works"
              class="learn-more-link"
              target="_blank"
              aria-label="Learn more about buy now pay later with zip"
              >Learn more at Zip.co
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L12 4"
                  stroke="#6542BE"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.5 4H12V10.5"
                  stroke="#6542BE"
                  stroke-width="1.5"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="divider"></div>
          <p class="disclaimer">
            <sup>1</sup>&nbsp;For example, for a $335 purchase, you'd make four
            $85.25 payments every two weeks starting today for a 31.11% annual
            percentage rate and a total of payments of $341. A $6 installment
            fee is charged at commencement - you pay $1.50 of this fee as a
            prepaid finance charge when you make your initial payment today. The
            remaining $4.50 is included in your future payments. Actual
            installment fees vary and can range from $0 to $7.50 depending on
            the purchase price and Zip product used. Actual amount of fee for
            your purchase will be reflected in checkout. Estimation of
            installment payment and annual percentage rate excludes potential
            tax and shipping costs.
          </p>
          <p class="disclaimer">
            <sup>2</sup>&nbsp;For a $400 purchase, you'd make eight $52.13
            payments every two weeks starting today for a 31.66% annual
            percentage rate and a total of payments of $417. A $17 installment
            fee is charged at commencement - you pay $2.13 of this fee as a
            prepaid finance charge when you make your initial payment today. The
            remaining $15.87 is included in your future payments. Actual
            installment fees vary and can range from $8 to $77 depending on the
            purchase price. Actual amount of fee for your purchase will be
            reflected in checkout. Estimation of installment payment and annual
            percentage rate excludes potential tax and shipping costs.
          </p>
          <p class="disclaimer">
            Zip Checkout financing through Zip issued by WebBank. All loans are
            subject to credit approval.
          </p>
          <p class="disclaimer">
            Eligibility criteria apply. See payment terms:
            <a href="https://zip.co/us/loan-terms-of-service" target="_blank"
              >Zip Loan Terms of Service | Zip, previously Quadpay</a
            >. Loans to California residents, other than loans through the Zip
            Pay Anywhere and Zip Web Checkout are made or arranged pursuant to a
            California Lenders Law license. Zip Co US Inc. ID 1963958
            <a
              href="https://mortgage.nationwidelicensingsystem.org/about/sitepages/NMLSConsumerAccess.aspx"
              target="_blank"
              >NMLS Consumer Access</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Installment } from "@/calculate-installments/installment-model";
import { getWidgetData } from "@/services/widget-data";
import {
  PaymentScheduleMap,
  ScheduleType,
} from "@/services/widget-data/models";
import widgetApi from "@/widget/widget-api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PayInNModal extends Vue {
  paymentScheduleMap: PaymentScheduleMap = {
    [ScheduleType.payin4]: {},
    [ScheduleType.payin8]: {},
  };

  ScheduleType = ScheduleType;

  paymentScheduleAccordionVisibility = {
    [ScheduleType.payin4]: true,
    [ScheduleType.payin8]: true,
  };

  @Prop() totalAmount: number;

  @Watch("totalAmount")
  onTotalAmountChange() {
    this.preparePaymentSchedules();
  }

  @Prop() widgetId: string;

  mounted() {
    this.preparePaymentSchedules();
  }

  preparePaymentSchedules() {
    const widgetData = getWidgetData();
    this.paymentScheduleMap = widgetData.getMappedPaymentSchedules();
  }

  closeModal() {
    widgetApi.hideModal(this.widgetId);
  }

  get payIn4InstallmentPrice() {
    return this.payIn4Installments?.[3]?.totalAmountIncludingFees ?? 0;
  }

  get payIn8InstallmentPrice() {
    return this.payIn8Installments?.[7]?.totalAmountIncludingFees ?? 0;
  }

  get payIn4InstallmentInterval() {
    if (
      this.paymentScheduleMap[ScheduleType.payin4].installmentIntervalDays ===
      14
    ) {
      return "bi-weekly";
    } else if (
      this.paymentScheduleMap[ScheduleType.payin4].installmentIntervalDays === 7
    ) {
      return "weekly";
    }
  }

  get payIn4Weeks() {
    if (
      this.paymentScheduleMap[ScheduleType.payin4].installmentIntervalDays ===
      14
    ) {
      return 6;
    } else if (
      this.paymentScheduleMap[ScheduleType.payin4].installmentIntervalDays === 7
    ) {
      return 3;
    }
  }

  get payIn8InstallmentInterval() {
    if (
      this.paymentScheduleMap[ScheduleType.payin8].installmentIntervalDays ===
      14
    ) {
      return "bi-weekly";
    } else if (
      this.paymentScheduleMap[ScheduleType.payin8].installmentIntervalDays === 7
    ) {
      return "weekly";
    }
  }

  get payIn8Weeks() {
    if (
      this.paymentScheduleMap[ScheduleType.payin8].installmentIntervalDays ===
      14
    ) {
      return 14;
    } else if (
      this.paymentScheduleMap[ScheduleType.payin8].installmentIntervalDays === 7
    ) {
      return 7;
    }
  }

  get payIn4TotalWithFees() {
    return this.payIn4Installments.reduce((totalAmount, installment) => {
      totalAmount += installment.totalAmountIncludingFees;
      return totalAmount;
    }, 0);
  }

  get payIn4FinanceCharge() {
    return this.payIn4Installments.reduce((totalFee, installment) => {
      totalFee += installment.fee;
      return totalFee;
    }, 0);
  }

  get payIn4FinanceChargePerInstallment() {
    return this.payIn4Installments?.[0].fee ?? 0;
  }

  get payIn8TotalWithFees() {
    return this.payIn8Installments.reduce((totalAmount, installment) => {
      totalAmount += installment.totalAmountIncludingFees;
      return totalAmount;
    }, 0);
  }

  get payIn8FinanceCharge() {
    return this.payIn8Installments.reduce((totalFee, installment) => {
      totalFee += installment.fee;
      return totalFee;
    }, 0);
  }

  formatPercent(amount: number) {
    return `${(amount * 100).toFixed(2)}%`;
  }

  get payin8APR() {
    return this.paymentScheduleMap?.[ScheduleType.payin8]?.apr;
  }

  get payIn4APR() {
    return this.paymentScheduleMap?.[ScheduleType.payin4]?.apr;
  }

  get payIn8FinanceChargePerInstallment() {
    return this.payIn8Installments?.[0].fee ?? 0;
  }

  get isPayIn4Available() {
    return this.paymentScheduleMap[ScheduleType.payin4].isAvailable;
  }

  get isPayIn8Available() {
    return this.paymentScheduleMap[ScheduleType.payin8].isAvailable;
  }

  get isPayIn4UnderMin() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin4];
    return schedule.orderAmount < schedule.minimumOrderAmount;
  }

  get isPayIn4OverMax() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin4];
    return schedule.orderAmount > schedule.maximumOrderAmount;
  }

  get payIn4Min() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin4];
    return schedule.minimumOrderAmount;
  }

  get payIn4Max() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin4];
    return schedule.maximumOrderAmount;
  }

  get isPayIn8UnderMin() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin8];
    return schedule.orderAmount < schedule.minimumOrderAmount;
  }

  get isPayIn8OverMax() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin8];
    return schedule.orderAmount > schedule.maximumOrderAmount;
  }

  get payIn8Min() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin8];
    return schedule.minimumOrderAmount;
  }

  get payIn8Max() {
    const schedule = this.paymentScheduleMap[ScheduleType.payin8];
    return schedule.maximumOrderAmount;
  }

  toggleAccordion(scheduleType: ScheduleType) {
    if (scheduleType === ScheduleType.payin4 && !this.isPayIn4Available) {
      return;
    }

    if (scheduleType === ScheduleType.payin8 && !this.isPayIn8Available) {
      return;
    }

    Vue.set(
      this.paymentScheduleAccordionVisibility,
      scheduleType,
      !this.paymentScheduleAccordionVisibility[scheduleType]
    );
  }

  getAccordionCaretStyle(scheduleType: ScheduleType) {
    if (!this.paymentScheduleAccordionVisibility[scheduleType]) {
      return {
        transform: "rotate(180deg);",
      };
    }
  }

  formatPrice(amount: number) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }

  formatDate(installment: Installment) {
    if (installment.sequenceNumber === 0) {
      return "Today";
    } else {
      const date = new Date(installment.date);
      const options = {
        month: "short",
      };

      const dateWithOrdinal = this.formatOrdinalNumber(date.getDate());

      /* @ts-ignore-next */
      return `${date.toLocaleDateString(
        undefined,
        options
      )} ${dateWithOrdinal}`;
    }
  }

  formatOrdinalNumber(dateNumber: number) {
    let selector;

    if (dateNumber <= 0) {
      selector = 4;
    } else if ((dateNumber > 3 && dateNumber < 21) || dateNumber % 10 > 3) {
      selector = 0;
    } else {
      selector = dateNumber % 10;
    }

    return dateNumber + ["th", "st", "nd", "rd", ""][selector];
  }

  get payIn4Installments() {
    return this.paymentScheduleMap?.[ScheduleType.payin4]?.installments ?? [];
  }

  get payIn8Installments() {
    return this.paymentScheduleMap?.[ScheduleType.payin8]?.installments ?? [];
  }
}
</script>
