import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';
import fontScss from '../shared/styles/font.scss';
/**
 * Create Modal
 *  - To ensure modal is top-most layer, modal needs to be added
 *    at end of DOM, even if there are slow or no-load scripts
 *  - We'll return a promise that retries periodically until DOM
 *    is ready to add modal.
 *  - The promise eventually returns a copy of the modal element
 *    to be used modal element's properties can be modified to
 *    update the vue component's props reactively
 *      ex: modal.minimumOrderAmount = 100
 *  - If modalName is camelCase it will be converted to kebab-case
 *    because that is a requirement for a customElement tag name
 *  - modalName must be kebab-case or camelCase.
 */
export async function _createModal(modalName, modalComponent, modalStyles, widgetId) {
  const existingModal = document.querySelector(`#qp-modal-${widgetId}`);
  modalName = hyphenate(modalName); // convert to kebab-case if necessary
  if (existingModal) {
    // remove old modal before adding a new one
    existingModal.parentNode.removeChild(existingModal);
  }

  // we can only define a custom element once
  const customElementName = `quadpay-modal-${modalName}`;
  if (!window.customElements.get(customElementName)) {
    window.customElements.define(customElementName, wrap(Vue, modalComponent) as any);
  }

  // create new instance of element, set some attributes and return it
  const modal = document.createElement(customElementName);
  modal.setAttribute('id', `qp-modal-${widgetId}`);
  modal.setAttribute('modalName', modalName);
  modal.setAttribute('style', 'z-index: 2147483647; position: fixed;');

  // Add styles to logo
  const stylesheet = document.createElement('style');
  stylesheet.innerHTML = `${modalStyles}`;
  modal.shadowRoot.append(stylesheet);

  const fonts = document.createElement('style');
  fonts.innerHTML = `${fontScss}`;
  modal.append(fonts);

  return await _getInsertModalPromise(modal, widgetId);
}

// Utilities
const _insertModal = (modal, widgetId) => {
  // Only insert modal if one with the same widgetId doesn't exist
  if (!document.querySelector(`#qp-modal-${widgetId}`)) {
    document.body.appendChild(modal);
  }
};

async function _getInsertModalPromise(modalComponent, widgetId) {
  return await new Promise((resolve) => {
    // if time runs out: add an event listener to insert modal and return current modal object
    // modal object won't have responsive vue props until it is appended onto the DOM
    const timeout = setTimeout(() => {
      if (document.readyState !== 'loading') {
        _insertModal(modalComponent, widgetId);
      } else {
        document.addEventListener('DOMContentLoaded', () => { _insertModal(modalComponent, widgetId); });
      }
      clearInterval(retryInterval);
      resolve(modalComponent);
    }, 500);

    const retryInterval = setInterval(() => {
      if (document.readyState !== 'loading') {
        clearTimeout(timeout);
        clearInterval(retryInterval);
        _insertModal(modalComponent, widgetId);
        resolve(modalComponent);
      }
    }, 5);
  });
}

// Taken from @vuejs/vue-web-component-wrapper
// Converts camelCase to kebab-case
// TODO: improve regex to handle spaces, and other characters
//       that aren't accepted by window.customElement.define()
const hyphenateRE = /\B([A-Z])/g;
export const hyphenate = str => {
  return str.replace(hyphenateRE, '-$1').toLowerCase();
};