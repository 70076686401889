import { FeatureFlagKey, getWidgetData } from '@/services/widget-data';
import { WidgetApi } from '../api-definitions';
import getConfiguration, { EnvironmentConfiguration, getConfigurationForEnvironmentName } from '../shared/configuration';
import logger from '../shared/logger';
import baseModalScss from '@/widget/assets/styles/base-modal.scss';
import payInNModalScss from '@/widget/assets/styles/pay-in-n-modal.scss';
import fontScss from '../shared/styles/font.scss';
import { _createModal } from '@/widget/quadpay-modal';
import Modal from '@/widget/assets/components/BaseModal.vue';
import PayInNModal from '@/widget/assets/components/PayInNModal.vue';

let environmentConfiguration: EnvironmentConfiguration = getConfiguration();
logger.info(`Successfully instantiated the widget API in environment ${environmentConfiguration.environmentName}`);

/**
 * Displays the modal
 * @param widgetId The id to identify the modal to display
 */
function displayModal(widgetId) {
  const modalContainer = document
    .querySelector(`#qp-modal-${widgetId}`)
    .shadowRoot.getElementById('qp-modal__overlay');
  modalContainer.style.display = 'block';
  modalContainer.focus();
}

/**
 * Hides the modal
 */
function hideModal(widgetId): void {
  const modalContainer = document
    .querySelector(`#qp-modal-${widgetId}`)
    .shadowRoot.getElementById('qp-modal__overlay');
  const configuration = getConfiguration();

  // If our modal is open and we close it in any way, we should return focus to the link.
  if (modalContainer.style.display === 'block') {
    modalContainer.style.display = 'none';

    const widget = document.querySelector(configuration.tagname);
    if (widget) {
      const button: HTMLElement = widget.shadowRoot.querySelector('.qp-open-modal-button');
      button.focus();
    }
  }
}

/**
 * Puts the modal contents into the provided HTML element.
 * @param elementId the HTML element id selector to insert the modal contents into
 * @param merchantId the merchant id to use when populating the modal's contents
 * @param amount the decimal amount of money for the purchase
 */
async function dumpModalToTarget(elementId: string, merchantId: string, amount: number): Promise<void> {
  const element = document.getElementById(elementId);
  if (element == null) {
    console.error(`Element with ID ${elementId} was not found in the DOM.`);
    return;
  }

  // set attributes based on query parameters
  const guidMatchRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const guidIsValid = merchantId?.match(guidMatchRegex);

  if (!guidIsValid) { // don't do anything without a valid merchant id
    console.error('`merchantId` must be present and must be a valid GUID.');
    return;
  }

  if (typeof amount !== 'number') { // don't do anything without a valid amount field
    console.error('`amount` must be present and must be a valid number.');
    return;
  }

  // delete the existing dump if it exists - this allows us to call `setAttributes()` fresh with new Props to pass BaseModal.vue
  // also prevents slamming the DOM with endless new elements
  const existingModalDump = document.getElementById(`qp-modal-${merchantId}`);
  if (existingModalDump) {
    // remove the previous rendered modal as well
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }

    existingModalDump.remove();

    const existingModalStyles = document.getElementById('modal-stylesheet');
    const existingModalFonts = document.getElementById('modal-fonts');
    if (existingModalStyles) {
      existingModalStyles.remove();
    }

    if (existingModalFonts) {
      existingModalFonts.remove();
    }
  }

  // Load the widget data for the merchant
  const widgetData = getWidgetData();
  widgetData.setMerchantId(merchantId);
  await widgetData.load();

  // read widgetData to better understand the modal to display
  const paymentSchedules = await widgetData.fetchPaymentSchedules(amount.toString());
  const isMerchantPayInZ = !!paymentSchedules?.length;

  const isPayIn8FeatureEnabled = widgetData.isFeatureEnabled(
    FeatureFlagKey.PayIn8Widget,
    false,
  );

  const usePayInZModal = isMerchantPayInZ && isPayIn8FeatureEnabled;

  // insert the modal into the DOM
  if (usePayInZModal) {
    await _createModal('payInN', PayInNModal, payInNModalScss, merchantId);
  } else {
    await _createModal('base', Modal, baseModalScss, merchantId);
  }

  // then, select the qp-modal__container node and clone it
  const elementSelector = usePayInZModal ? 'qp-modal__overlay' : 'qp-modal__container';
  const modalContainer = document
    .querySelector(`#qp-modal-${merchantId}`)
    .shadowRoot.getElementById(elementSelector).cloneNode(true) as HTMLElement;

  // remove margin and box shadow
  modalContainer.style.margin = '0px';
  modalContainer.style.boxShadow = '0px 0px 0px black';
  modalContainer.style.display = 'block';

  if (usePayInZModal) {
    const container = modalContainer.querySelector<HTMLElement>('.modal-container');
    container.style.background = '#ffffff00';
    container.style.display = 'block';
  }

  // Add styles to the document
  const stylesheet = document.createElement('style');
  stylesheet.innerHTML = `${usePayInZModal ? payInNModalScss : baseModalScss}`;
  stylesheet.setAttribute('id', 'modal-stylesheet');

  const fonts = document.createElement('style');
  fonts.innerHTML = `${fontScss}`;
  fonts.setAttribute('id', 'modal-fonts');

  // add modal to the DOM
  element.appendChild(modalContainer);
  document.body.appendChild(stylesheet);
  document.body.appendChild(fonts);
}

/**
 * Updates the environment environmentConfigurationuration of widget to use the environment specified
 * @param environmentName The name of the environment to integrate with (e.g. Production, Sandbox, etc.)
 */
function updateEnvironment(environmentName: string): void {
  const currentEnvironment = environmentConfiguration;
  const loadedEnvironment = getConfigurationForEnvironmentName(environmentName);

  if (currentEnvironment?.environmentName !== loadedEnvironment?.environmentName) {
    environmentConfiguration = loadedEnvironment;
  }
}

/**
 * Retrieve widget ids
 */
function getWidgetIds() {
  const tagName = getConfiguration().tagname;
  const widgets = document.querySelectorAll(tagName);
  const widgetIds = [];
  widgets.forEach((widget) =>
    widgetIds.push(
      widget.getAttribute('widgetid') || widget.getAttribute('widgetId'),
    ),
  );
  return widgetIds;
}

function getWidgetVerbiage(attribute, isServiceFeeMerchant, isMFPPMerchant, hasFees): string {
  const WidgetVerbiageMap = Object.freeze({
    '4-easy-payments': '4 easy payments',
    '4-payments': '4 payments',
    '4-installments': 'Pay in 4 installments',
  });
  let verbiageKey: string = '4-payments';
  if (attribute === '4-installments' || (attribute === '4-easy-payments' && isMFPPMerchant)) {
    verbiageKey = attribute;
  } else if (isServiceFeeMerchant || isMFPPMerchant) {
    verbiageKey = '4-payments';
  } else if (WidgetVerbiageMap[attribute]) {
    verbiageKey = attribute;
  }
  verbiageKey = 'widget.' + verbiageKey;

  return verbiageKey;
}

async function getPromotionsVariation(): Promise<string> {
  const widgetData = getWidgetData();
  const displayPromotions = await widgetData.getABTestVariation('kh676WNtGYatLyKieth8fS', 'widget_promotions');
  return displayPromotions;
}

const widgetApi: WidgetApi = {
  displayModal,
  hideModal,
  dumpModalToTarget,
  updateEnvironment,
  getWidgetIds,
  getWidgetVerbiage,
  getPromotionsVariation,
};
export default widgetApi;

export const testables = {};
