import 'whatwg-fetch';
import associate from './order-id-association/quadpay-associate';
import getConfiguration from './shared/configuration';
import logger from './shared/logger';
import loadQuadpayButton from './sdk-checkout-integration/quadpay-button';
import virtualCheckout from './sdk-checkout-integration/virtual-checkout/virtual-checkout';
import apiCheckoutApi from './sdk-checkout-integration/api-checkout/api-checkout';
import widgetApi from './widget/widget-api';
import paymentMethods from './widget/payment-methods';
import './widget/quadpay-widget';
import { loadSecondChanceWidget } from './widget/assets/components/second-chance-widget/SecondChanceWidget';
import calculateInstallments from './calculate-installments/calculate-installments';
import {
  VirtualCheckoutApiName,
  AssociateApiName,
  CalculateInstallmentsApiName,
  WidgetApiName,
  MerchantFeesApiName,
  PaymentMethodsApiName,
  ApiCheckoutApiName,
} from './api-definitions';
import merchantFees from './merchant-fees/merchant-fees';
import { getLanguage } from './shared/translator';
import insertModal from '@/widget/merchant-insert-modal';
import load from './services';

/**
 * This is the public API exposed to consumers of this library.  Every bit of functionality
 * is made under the `window.quadpay` module.  It is important that we don't break contracts
 * to anything that falls under here!
 */
(function(window) {
  const qpjs = {
    [AssociateApiName]: associate,
    [VirtualCheckoutApiName]: virtualCheckout,
    [ApiCheckoutApiName]: apiCheckoutApi,
    [CalculateInstallmentsApiName]: calculateInstallments,
    [MerchantFeesApiName]: merchantFees,
    [WidgetApiName]: widgetApi,
    [PaymentMethodsApiName]: paymentMethods,
    getLanguage,
    insertModal,
  } as any;

  window.quadpay = window.quadpay || qpjs;
}(window));

const configuration = getConfiguration();
logger.info(`Loaded configuration for environment ${configuration.environmentName}`, configuration);

try {
  // Load fingerprint and dependent services.  We'll check when ready within the widget itself
  void load();
} catch (e) {
  e.message = `Error loading fingerprint and dependent services - ${e.message}`;
}

try {
  // Set up VC button as a custom web component
  loadQuadpayButton();
} catch (e) {
  e.message = `Error loading checkout button - ${e.message}`;
}

try {
  // Set up second chance widget component
  loadSecondChanceWidget();
} catch (e) {
  e.message = `Error loading second chance widget - ${e.message}`;
}

logger.info(`quadpay.js version ${process.env.APPLICATION_VERSION} successfully initialized!`);