var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pay-in-n-modal", attrs: { id: "qp-modal__overlay" } },
    [
      _c(
        "div",
        { staticClass: "modal-container", on: { click: _vm.closeModal } },
        [
          _c(
            "div",
            {
              staticClass: "modal-content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "button",
                  {
                    staticClass: "close-button",
                    attrs: {
                      "aria-label":
                        "Close Zip buy now pay later informational popup",
                    },
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "g",
                          { attrs: { "clip-path": "url(#clip0_5174_19759)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: "M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z",
                                fill: "white",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M17.25 7.8075L16.1925 6.75L12 10.9425L7.8075 6.75L6.75 7.8075L10.9425 12L6.75 16.1925L7.8075 17.25L12 13.0575L16.1925 17.25L17.25 16.1925L13.0575 12L17.25 7.8075Z",
                                fill: "#1A0826",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c(
                            "clipPath",
                            { attrs: { id: "clip0_5174_19759" } },
                            [
                              _c("rect", {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "white",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "87",
                      height: "30",
                      viewBox: "0 0 87 30",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c(
                      "g",
                      { attrs: { "clip-path": "url(#clip0_3512_10446)" } },
                      [
                        _c("path", {
                          attrs: {
                            d: "M1.77729 24.2319L2.47044 29.8594H26.8016L26.0078 23.4171H14.6804L14.5797 22.6199L25.0301 15.3628L24.331 9.72949H0L0.793855 16.1718H12.1626L12.2633 16.9748L1.77729 24.2319Z",
                            fill: "#1A0826",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M27.1635 9.72949L29.6516 29.8594H54.0004L51.5418 9.72949H27.1635Z",
                            fill: "#AA8FFF",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M79.5399 16.9925C78.9473 12.4693 75.3928 9.72945 70.5347 9.74716H54.3376L56.826 29.8772H64.1129L63.6153 25.85H71.3168C77.3953 25.85 80.1737 22.0767 79.5399 16.9925ZM70.5408 20.2048H62.9221L62.3297 15.3746H69.9897C71.7671 15.3746 72.715 16.402 72.8632 17.7838C72.9089 18.1018 72.8815 18.426 72.7827 18.7319C72.684 19.0377 72.5166 19.317 72.2931 19.5487C72.0698 19.7804 71.7966 19.9583 71.4939 20.069C71.1913 20.1796 70.939 20.2048 70.5408 20.2048Z",
                            fill: "#1A0826",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M34.5856 7.13723C35.8712 5.77321 35.6581 3.4939 34.1058 2.04129C32.5536 0.588674 30.2432 0.517822 28.9516 1.88186C27.6601 3.2459 27.8794 5.5311 29.4316 6.98372C30.9838 8.43634 33.2942 8.50128 34.5856 7.13723Z",
                            fill: "#1A0826",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M82.7447 12.3099H82.259L81.9924 10.1487H81.2816L81.2281 9.72949H83.1654L83.2186 10.1487H82.4783L82.7447 12.3099Z",
                            fill: "#1A0826",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M84.4268 12.3099H83.9706L83.6566 9.72949H84.1247L85.0133 10.7688L85.6532 9.72949H86.0915L86.4114 12.3099H85.9553L85.7243 10.4145L85.6591 10.5385L85.12 11.4183H85.0489L84.2964 10.5385L84.2194 10.4322L84.4268 12.3099Z",
                            fill: "#1A0826",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("defs", [
                      _c("clipPath", { attrs: { id: "clip0_3512_10446" } }, [
                        _c("rect", {
                          attrs: {
                            width: "86.8421",
                            height: "30",
                            fill: "white",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "modal-title" }, [
                  _vm._v("Shop now, pay over time"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "payment-schedule-container" }, [
                  _c("div", { staticClass: "payment-schedule" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-visible",
                        class: {
                          "payment-schedule-disabled": !_vm.isPayIn4Available,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleAccordion(_vm.ScheduleType.payin4)
                          },
                        },
                      },
                      [
                        _vm.isPayIn4Available
                          ? _c("div", { staticClass: "accordion-left" }, [
                              _c("h3", [
                                _vm._v(
                                  "\n                  4 payments of " +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.payIn4InstallmentPrice
                                      )
                                    ) +
                                    "\n                  "
                                ),
                                _c("sup", [_vm._v("1")]),
                              ]),
                              _vm._v(" "),
                              _vm.payIn4InstallmentInterval
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Pay " +
                                        _vm._s(_vm.payIn4InstallmentInterval) +
                                        " over\n                  " +
                                        _vm._s(_vm.payIn4Weeks) +
                                        " weeks\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "accordion-left" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _vm.isPayIn4UnderMin
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Available on orders over " +
                                        _vm._s(_vm.formatPrice(_vm.payIn4Min)) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm.isPayIn4OverMax
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Available on orders under " +
                                        _vm._s(_vm.formatPrice(_vm.payIn4Max)) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      "\n                  Not available on this order\n                "
                                    ),
                                  ]),
                            ]),
                        _vm._v(" "),
                        _vm.isPayIn4Available
                          ? _c("div", { staticClass: "accordion-right" }, [
                              _c(
                                "svg",
                                {
                                  style: !_vm
                                    .paymentScheduleAccordionVisibility[
                                    _vm.ScheduleType.payin4
                                  ]
                                    ? {
                                        transform: "rotate(180deg)",
                                      }
                                    : {},
                                  attrs: {
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 16 16",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M3 10L8 5L13 10",
                                      stroke: "#1A0826",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "square",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isPayIn4Available
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.paymentScheduleAccordionVisibility[
                                    _vm.ScheduleType.payin4
                                  ],
                                expression:
                                  "paymentScheduleAccordionVisibility[ScheduleType.payin4]",
                              },
                            ],
                            staticClass: "accordion-hidden",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "installment-graphic-container" },
                              _vm._l(
                                _vm.payIn4Installments,
                                function (installment, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: installment.date,
                                      staticClass: "installment",
                                    },
                                    [
                                      _c("div", { staticClass: "donut-icon" }, [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              height: "42",
                                              width: "42",
                                              "view-box": "0 0 42 42",
                                              role: "img",
                                            },
                                          },
                                          [
                                            _c("title", [
                                              _vm._v(
                                                "\n                        A donut representing installment {sequence} of {total}\n                        for the current payment plan.\n                      "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("circle", {
                                              attrs: {
                                                cx: "21",
                                                cy: "21",
                                                r: "15.9154943092",
                                                "stroke-width": "5.5",
                                                stroke: "#E2E1DF",
                                                fill: "none",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("circle", {
                                              staticClass: "donut-path",
                                              attrs: {
                                                cx: "21",
                                                cy: "21",
                                                r: "15.9154943092",
                                                "stroke-width": "5.5",
                                                stroke: "#AA8FFF",
                                                fill: "none",
                                                "stroke-dasharray": `${
                                                  (100 * (index + 1)) / 4
                                                } ${
                                                  100 - (100 * (index + 1)) / 4
                                                }`,
                                                "stroke-dashoffset": "25",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "content" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "price",
                                            class: { first: index === 0 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    installment.totalAmountIncludingFees
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "due-date",
                                            class: { first: index === 0 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatDate(installment)
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm.payIn4APR
                              ? _c("p", { staticClass: "fee-subtext" }, [
                                  _vm._v(
                                    "\n                Includes fee:\n                " +
                                      _vm._s(
                                        _vm.formatPrice(
                                          _vm.payIn4FinanceChargePerInstallment
                                        )
                                      ) +
                                      " per\n                installment (" +
                                      _vm._s(
                                        _vm.formatPrice(_vm.payIn4FinanceCharge)
                                      ) +
                                      " per order\n                (" +
                                      _vm._s(_vm.formatPercent(_vm.payIn4APR)) +
                                      "\n                APR))\n              "
                                  ),
                                ])
                              : _vm.payIn4FinanceCharge
                              ? _c("p", { staticClass: "fee-subtext" }, [
                                  _vm._v(
                                    "\n                Includes finance charge:\n                " +
                                      _vm._s(
                                        _vm.formatPrice(
                                          _vm.payIn4FinanceChargePerInstallment
                                        )
                                      ) +
                                      " per\n                installment (" +
                                      _vm._s(
                                        _vm.formatPrice(_vm.payIn4FinanceCharge)
                                      ) +
                                      "\n                per order)\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "payment-schedule" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-visible",
                        class: {
                          "payment-schedule-disabled": !_vm.isPayIn8Available,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleAccordion(_vm.ScheduleType.payin8)
                          },
                        },
                      },
                      [
                        _vm.isPayIn8Available
                          ? _c("div", { staticClass: "accordion-left" }, [
                              _c("h3", [
                                _vm._v(
                                  "\n                  8 payments of " +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.payIn8InstallmentPrice
                                      )
                                    ) +
                                    "\n                  "
                                ),
                                _c("sup", [_vm._v("2")]),
                              ]),
                              _vm._v(" "),
                              _vm.payIn8InstallmentInterval
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Pay " +
                                        _vm._s(_vm.payIn8InstallmentInterval) +
                                        " over\n                  " +
                                        _vm._s(_vm.payIn8Weeks) +
                                        " weeks\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _c("div", { staticClass: "accordion-left" }, [
                              _vm._m(1),
                              _vm._v(" "),
                              _vm.isPayIn8UnderMin
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Available on orders over " +
                                        _vm._s(_vm.formatPrice(_vm.payIn8Min)) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm.isPayIn8OverMax
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  Available on orders under " +
                                        _vm._s(_vm.formatPrice(_vm.payIn8Max)) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      "\n                  Not available on this order\n                "
                                    ),
                                  ]),
                            ]),
                        _vm._v(" "),
                        _vm.isPayIn8Available
                          ? _c("div", { staticClass: "accordion-right" }, [
                              _c(
                                "svg",
                                {
                                  style: !_vm
                                    .paymentScheduleAccordionVisibility[
                                    _vm.ScheduleType.payin8
                                  ]
                                    ? {
                                        transform: "rotate(180deg)",
                                      }
                                    : {},
                                  attrs: {
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 16 16",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M3 10L8 5L13 10",
                                      stroke: "#1A0826",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "square",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isPayIn8Available
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.paymentScheduleAccordionVisibility[
                                    _vm.ScheduleType.payin8
                                  ],
                                expression:
                                  "paymentScheduleAccordionVisibility[ScheduleType.payin8]",
                              },
                            ],
                            staticClass: "accordion-hidden",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "installment-graphic-container" },
                              _vm._l(
                                _vm.payIn8Installments,
                                function (installment, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: installment.date,
                                      staticClass: "installment",
                                    },
                                    [
                                      _c("div", { staticClass: "donut-icon" }, [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              height: "42",
                                              width: "42",
                                              "view-box": "0 0 42 42",
                                              role: "img",
                                            },
                                          },
                                          [
                                            _c("title", [
                                              _vm._v(
                                                "\n                        A donut representing installment {sequence} of {total}\n                        for the current payment plan.\n                      "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("circle", {
                                              attrs: {
                                                cx: "21",
                                                cy: "21",
                                                r: "15.9154943092",
                                                "stroke-width": "5.5",
                                                stroke: "#E2E1DF",
                                                fill: "none",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("circle", {
                                              staticClass: "donut-path",
                                              attrs: {
                                                cx: "21",
                                                cy: "21",
                                                r: "15.9154943092",
                                                "stroke-width": "5.5",
                                                stroke: "#AA8FFF",
                                                fill: "none",
                                                "stroke-dasharray": `${
                                                  (100 * (index + 1)) / 8
                                                } ${
                                                  100 - (100 * (index + 1)) / 8
                                                }`,
                                                "stroke-dashoffset": "25",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "content" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "price",
                                            class: { first: index === 0 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    installment.totalAmountIncludingFees
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "due-date",
                                            class: { first: index === 0 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatDate(installment)
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm.payIn8FinanceCharge
                              ? _c("p", { staticClass: "fee-subtext" }, [
                                  _vm._v(
                                    "\n                Includes fee:\n                " +
                                      _vm._s(
                                        _vm.formatPrice(
                                          _vm.payIn8FinanceChargePerInstallment
                                        )
                                      ) +
                                      " per\n                installment (" +
                                      _vm._s(
                                        _vm.formatPrice(_vm.payIn8FinanceCharge)
                                      ) +
                                      " per order\n                (" +
                                      _vm._s(_vm.formatPercent(_vm.payin8APR)) +
                                      "\n                APR))\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "features-container" }, [
                  _c("div", { staticClass: "feature-row" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18V6C21.75 5.58579 21.4142 5.25 21 5.25Z",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M15.7495 15.75H18.7495",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "square",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M11.2495 15.75H12.7495",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "square",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M2.24945 9.0799H21.7494",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("No impact to your credit score")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "feature-row" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M3.75 10.75V5.25C3.75 5.05109 3.82902 4.86032 3.96967 4.71967C4.11032 4.57902 4.30109 4.5 4.5 4.5H19.5C19.6989 4.5 19.8897 4.57902 20.0303 4.71967C20.171 4.86032 20.25 5.05109 20.25 5.25V10.75C20.25 18.6264 13.5651 21.236 12.2303 21.6785C12.0811 21.7298 11.9189 21.7298 11.7697 21.6785C10.4349 21.236 3.75 18.6264 3.75 10.75Z",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M16.125 9.75L10.625 15L7.875 12.375",
                            stroke: "#1A0826",
                            "stroke-width": "1.5",
                            "stroke-linecap": "square",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Safe and secure checkout")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "learn-more-link",
                      attrs: {
                        href: "https://zip.co/us/how-it-works",
                        target: "_blank",
                        "aria-label":
                          "Learn more about buy now pay later with zip",
                      },
                    },
                    [
                      _vm._v("Learn more at Zip.co\n            "),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "16",
                            height: "16",
                            viewBox: "0 0 16 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M4 12L12 4",
                              stroke: "#6542BE",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M5.5 4H12V10.5",
                              stroke: "#6542BE",
                              "stroke-width": "1.5",
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "divider" }),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("p", { staticClass: "disclaimer" }, [
                  _vm._v(
                    "\n          Zip Checkout financing through Zip issued by WebBank. All loans are\n          subject to credit approval.\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(4),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", [_vm._v("4 bi-weekly payments "), _c("sup", [_vm._v("1")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", [_vm._v("8 bi-weekly payments "), _c("sup", [_vm._v("2")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "disclaimer" }, [
      _c("sup", [_vm._v("1")]),
      _vm._v(
        " For example, for a $335 purchase, you'd make four\n          $85.25 payments every two weeks starting today for a 31.11% annual\n          percentage rate and a total of payments of $341. A $6 installment\n          fee is charged at commencement - you pay $1.50 of this fee as a\n          prepaid finance charge when you make your initial payment today. The\n          remaining $4.50 is included in your future payments. Actual\n          installment fees vary and can range from $0 to $7.50 depending on\n          the purchase price and Zip product used. Actual amount of fee for\n          your purchase will be reflected in checkout. Estimation of\n          installment payment and annual percentage rate excludes potential\n          tax and shipping costs.\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "disclaimer" }, [
      _c("sup", [_vm._v("2")]),
      _vm._v(
        " For a $400 purchase, you'd make eight $52.13\n          payments every two weeks starting today for a 31.66% annual\n          percentage rate and a total of payments of $417. A $17 installment\n          fee is charged at commencement - you pay $2.13 of this fee as a\n          prepaid finance charge when you make your initial payment today. The\n          remaining $15.87 is included in your future payments. Actual\n          installment fees vary and can range from $8 to $77 depending on the\n          purchase price. Actual amount of fee for your purchase will be\n          reflected in checkout. Estimation of installment payment and annual\n          percentage rate excludes potential tax and shipping costs.\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "disclaimer" }, [
      _vm._v(
        "\n          Eligibility criteria apply. See payment terms:\n          "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://zip.co/us/loan-terms-of-service",
            target: "_blank",
          },
        },
        [_vm._v("Zip Loan Terms of Service | Zip, previously Quadpay")]
      ),
      _vm._v(
        ". Loans to California residents, other than loans through the Zip\n          Pay Anywhere and Zip Web Checkout are made or arranged pursuant to a\n          California Lenders Law license. Zip Co US Inc. ID 1963958\n          "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://mortgage.nationwidelicensingsystem.org/about/sitepages/NMLSConsumerAccess.aspx",
            target: "_blank",
          },
        },
        [_vm._v("NMLS Consumer Access")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }