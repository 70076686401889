import getConfiguration from '@/shared/configuration';
import Translator from '@/shared/translator';
import getSecondChanceWidgetTemplate from './SecondChanceWidgetTemplate';
import { parseFullAmount, calcInstallmentAmount, calculateCustomerFee } from '@/widget/shared-api';
import fontCss from '@/shared/styles/font.scss';
import logger from '@/shared/logger';
import { MerchantFeeTierArray, getWidgetData } from '@/services/widget-data';
import { BankPartner } from '@/services/widget-data/models';
import { v4 as uuidv4 } from 'uuid';
import { EntrySource } from '@/sdk-checkout-integration/sdk-integration-models';
import { ENTRY_SOURCE_KEY } from '@/services/storage';
import { getEventBus } from '@/services/events';
import { getAnalytics } from '@/services/analytics';

const configuration = getConfiguration();

/**
 * Creates a "second chance widget" which loads a quadpay-button to allow
 * customer to start a checkout (VC or api).
 */
export class SecondChanceWidget extends HTMLElement {
  widgetId = uuidv4();
  shadow: ShadowRoot;
  translator = new Translator();
  shopifyWindowObject = (window as any).Shopify;
  merchantFeeTiers: MerchantFeeTierArray;
  merchantBankPartner: BankPartner;
  merchantName: string;
  installmentAmount: string;
  minAmount: number = 35;
  maxAmount: number = 1500;
  loadTimeMilliseconds = 0;
  startLoad = 0;
  eventBus;
  analytics;
  private readonly readyPromise: Promise<void>;

  private readyPromiseResolver: () => void;

  constructor() {
    super();
    this.startLoad = performance.now();
    this.eventBus = getEventBus();
    this.readyPromise = new Promise((resolve) => {
      this.readyPromiseResolver = resolve;
    });
  }

  // HTML Attributes
  get amount() { return this.getAttribute('amount') || ''; }
  get backgroundColor() { return this.getAttribute('backgroundColor') || ''; }
  get borderStyle() { return this.getAttribute('borderStyle') || ''; }
  get shouldHideBorder() { return this.getAttribute('hideBorder') || ''; }
  get currency() { return this.getAttribute('currency') || 'USD'; }
  get integrationType() { return this.getAttribute('integrationType'); }
  get merchantId() { return this.getAttribute('merchantId'); }

  // Main "Mounted" lifecycle hook that fires when the component is placed on the page
  connectedCallback() {
    // Load widget data
    const widgetData = getWidgetData();

    // Set website url based on Shopify.shop value
    if (this.shopifyWindowObject?.shop) {
      widgetData.setWebsiteDomain(this.shopifyWindowObject.shop);
    }

    // Set merchant id so if we call feature flags load again it will be used
    if (this.merchantId) {
      widgetData.setMerchantId(this.merchantId);
    }

    this.analytics = getAnalytics();
    this.analytics.merchantId = this.merchantId;

    const loadingPromises = [];
    loadingPromises.push(this.analytics.initialise());
    loadingPromises.push(widgetData.load());

    // Calls our backend service to fetch widget data asynchronously
    Promise.all(loadingPromises).then(() => {
      this.merchantFeeTiers = getWidgetData().getMerchantFeeTiers();
      this.merchantBankPartner = getWidgetData().getMerchantBankPartner() as BankPartner;
      this.merchantName = getWidgetData().getMerchantName();
      this.mountWidget();
    }).catch((error) => {
      logger.error('error fetching zip widget data', error);
    });
  }

  private mountWidget() {
    // Handle multiple loads
    if (!this.shadow) {
      // load fonts for component
      const fontStyle = document.createElement('style');
      fontStyle.innerHTML = `${fontCss}`;
      this.appendChild(fontStyle);

      const checkoutButtonProperties = this.getCheckoutButtonProperties();

      // load html template for component
      const template = document.createElement('template');
      template.innerHTML = getSecondChanceWidgetTemplate(
        this.formatFirstInstallmentAmount(),
        this.backgroundColor,
        this.borderStyle,
        this.shouldHideBorder,
        checkoutButtonProperties,
      );

      this.shadow = this.attachShadow({ mode: 'open' });
      this.shadow.appendChild(template.content.cloneNode(true));

      // Update template based on min/max
      this.handleMinMax();

      // The setTimeout function is used to be able to run the translateComponent method as async in a synchronous context
      setTimeout(async () => {
        await this.readyPromise;
        await this.translator.translateComponent(this.currency, this);
      }, 1);

      // set up mutation listeners for "reactive" amount attribute
      this.listenForAmountChange();

      // set up click listeners to open modal
      this.setUpOpenModalClickListener();

      this.readyPromiseResolver();

      this.publishLoadEvent();
    }
  }

  private publishLoadEvent() {
    this.loadTimeMilliseconds = Math.round(performance.now() - this.startLoad);

    const domain = window.location.hostname;
    const path = window.location.pathname || '/';
    const displayOptions = this.getCheckoutButtonProperties()
      .reduce((previous, current) => {
        previous[current.name] = current.value;
        return previous;
      }, {});

    this.eventBus.publishViewedSecondChanceWidgetEvent(
      this.amount,
      domain,
      path,
      this.loadTimeMilliseconds,
      displayOptions,
    );
  }

  // Get all attributes from this second chance element
  // so we can pass them down to the button.
  private getCheckoutButtonProperties() {
    const mappedHTMLAttributes = [];
    for (let i = 0; i < this.attributes.length; i++) {
      mappedHTMLAttributes.push({
        name: this.attributes[i].name,
        value: this.attributes[i].value,
      });
    }

    // Add computed button color from widget background color
    const buttonColor = this.backgroundColor.toLowerCase() === 'black' ? 'white' : '';
    mappedHTMLAttributes.push({ name: 'buttonColor', value: buttonColor });
    mappedHTMLAttributes.push({ name: 'entrySource', value: EntrySource.SecondChance });

    return mappedHTMLAttributes;
  }

  private formatFirstInstallmentAmount(): string {
    const amount = parseFullAmount(this.amount);

    // calcInstallmentAmount needs the fee "per installment" rather than total fee
    const fee = calculateCustomerFee(amount, this.merchantFeeTiers) / 4;
    const installmentFee = calcInstallmentAmount(amount, fee, true);
    this.installmentAmount = installmentFee;

    return installmentFee;
  }

  // Checks if amount is outside of mix/max bounds, and hides/shows elements of template accordingly
  private handleMinMax() {
    const amount = parseFullAmount(this.amount);
    const installmentElement = this.shadow?.getElementById('zip-installment-message');
    const minElement = this.shadow?.getElementById('zip-min-message');
    const maxElement = this.shadow?.getElementById('zip-max-message');

    if (amount < this.minAmount) {
      installmentElement.classList.add('hidden');
      minElement.classList.remove('hidden');
    } else if (amount > this.maxAmount) {
      installmentElement.classList.add('hidden');
      maxElement.classList.remove('hidden');
    } else { // amount is valid
      installmentElement.classList.remove('hidden');
      maxElement.classList.add('hidden');
      minElement.classList.add('hidden');
    }
  }

  // Handles "reactive" portions of the widget if the merchant updates amount after it's rendered
  private listenForAmountChange() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          if (mutation.attributeName === 'amount') {
            this.handleMinMax();
            const amountElement = this.shadow?.getElementById('zip-first-installment-amount');
            amountElement.innerText = this.formatFirstInstallmentAmount();
          }

          // We need to make sure all attributes of the second chance widget are set on the nested zip-button
          this.setAllButtonAttributes();
        }
      });
    });

    observer.observe(this, {
      attributes: true,
    });
  }

  // Sets all attributes from this second chance widget onto the nested button
  private setAllButtonAttributes() {
    const button = this.shadow?.querySelector('zip-button');
    const buttonAttributes = this.getCheckoutButtonProperties();

    buttonAttributes.forEach((attribute) => {
      button.setAttribute(attribute.name, attribute.value);
    });
  }

  // Allows merchant to pass down click listener for checkout button
  async onClickCheckoutButton(clickListener: () => void) {
    await this.readyPromise;
    // Save entry source in session storage so we can have correct tracking for api checkout
    window.sessionStorage.setItem(ENTRY_SOURCE_KEY, EntrySource.SecondChance);

    this.shadow.querySelector('zip-button').addEventListener('click', clickListener);
    logger.info('Successfully registered click listener to zip-button');
  }

  private setUpOpenModalClickListener() {
    // convert html attribute array into object
    const buttonPropertiesMap = this.getCheckoutButtonProperties().reduce((newMap, currentValue) => {
      newMap[currentValue.name] = currentValue.value;
      return newMap;
    }, {});

    const modalOptions = {
      ...buttonPropertiesMap,
      hasFees: this.merchantFeeTiers?.length > 0,
      bankPartner: this.merchantBankPartner,
      merchantName: this.merchantName,
    };

    const widgetId = this.widgetId;

    // Create modal if it doesn't exist. Otherwise, open it
    const clickListener = () => {
      if (!this.shadow.getElementById(`qp-modal-${widgetId}`)) {
        // Pass along all html attributes in case they are needed for modal options
        window.quadpay.insertModal(widgetId, modalOptions);
      } else {
        window.quadpay.widget.displayModal(widgetId);
      }
    };

    this.shadow.getElementById('zip-logo').addEventListener('click', clickListener);
    this.shadow.getElementById('zip-info-icon').addEventListener('click', clickListener);
  }
}

export function loadSecondChanceWidget() {
  if (configuration.killSwitchDomains.some(domain => window.location.hostname.includes(domain))) { return; };

  // Handle multiple reloads
  if (!customElements.get('zip-second-chance-widget')) {
    customElements.define('zip-second-chance-widget', SecondChanceWidget);
  }
}
