import { supportsPopups } from 'belter';
import getConfiguration, { EnvironmentConfiguration, getConfigurationForEnvironmentName } from '../../shared/configuration';
import { Card, Cardholder, CheckoutFlow, VirtualSuccessCallback, CheckoutConfiguration, EntrySource } from '@/sdk-checkout-integration/sdk-integration-models';
import { Customer, Order } from '../sdk-integration-models';
import zoidComponentInit from '../zoid-component';
import logger from '../../shared/logger';
import { VirtualCheckoutApi } from '../../api-definitions';
import { convertShippingPreferenceToJSON, isShippingLocationsValidAndNotEmpty } from '@/utils/shippingLocation';

// This must match what's in package.json!  This is to ensure that the gateway loads the same (read compatible) version of post robot for the IE bridge.
const postRobotVersion = '10.0.44';

// Used for UUID validation with merchant IDs
const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[489ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

let environmentConfiguration: EnvironmentConfiguration = getConfiguration();
logger.info(`Successfully instantiated the virtual checkout API in environment ${environmentConfiguration.environmentName}`);

// Default unhappy path callbacks to empty methods so they are always defined and easier to prevent errors
let onSuccessCallback: (card: Card, cardholder: Cardholder, customer: Customer) => Promise<void> = async () => {};
let onCompleteCallback: (result: VirtualSuccessCallback) => Promise<void> = async () => {};
let onCloseCallback: (message: string) => Promise<void> = async () => {};
let onErrorCallback: (errorMessages: string[]) => Promise<void> = async () => {};
let testCardDetails: Card | undefined;

let zoidComponent: any;

// Result from zoid callback key
const callbackResult = 'qp-vc-result';

/**
 * Ensures the amount passed in is not null/undefined and is a number.
 * Returns if it is valid and the actual value.
 * @param amount
 * @returns
 */
function isValidAmount(amount: number | string | undefined | null): Readonly<[boolean, string]> {
  // Null covers undefined as well.
  if (amount != null) {
    const amountAsNumber = Number(amount);
    if (!isNaN(amountAsNumber) && amountAsNumber >= 0) {
      return [true, amountAsNumber.toFixed(2)];
    }
  }

  return [false, '0.00'];
}

/**
 * Constructs the gateway redirect URL to authorize a virtual checkout order.
 * @param merchantId
 * @param order
 * @param configuration
 */
function buildGatewayUrl(merchantId: string, order: Order, configuration: CheckoutConfiguration): string {
  // assign authorize path
  let checkoutPath: string;

  switch (configuration.checkoutFlow) {
    case CheckoutFlow.fiserv:
      checkoutPath = 'virtual/fiserv/authorize';
      break;
    case CheckoutFlow.cardSecure:
      checkoutPath = 'virtual/cardsecure/authorize';
      break;
    case CheckoutFlow.braintree:
      checkoutPath = 'virtual/braintree/authorize';
      break;
    default:
      checkoutPath = 'virtual/';
      break;
  }
  const url = new URL(`${environmentConfiguration.gatewayHost}/${checkoutPath}`);
  if (merchantId) url.searchParams.append('merchantId', merchantId.trim());
  if (order?.amount) url.searchParams.append('order.amount', order.amount.toFixed(2));
  if (order?.currency) url.searchParams.append('order.currency', order.currency);
  if (order?.merchantReference) url.searchParams.append('merchantReference', order.merchantReference);

  // If no entry source specified, assume merchant is opening checkout through a custom button
  const entrySource = configuration?.entrySource || EntrySource.MerchantButton;
  logger.debug('entry source - vc', entrySource);
  url.searchParams.append('entrySource', entrySource);

  // Tax amount logic.
  const taxAmountObject = isValidAmount(order?.taxAmount);
  if (taxAmountObject[0]) {
    url.searchParams.append('order.taxAmount', taxAmountObject[1]);
  }

  // Shipping amount logic.
  const shippingAmountObject = isValidAmount(order?.shippingAmount);
  if (shippingAmountObject[0]) {
    url.searchParams.append('order.shippingAmount', shippingAmountObject[1]);
  }

  if (order?.customer?.firstName) url.searchParams.append('order.firstName', order.customer.firstName);
  if (order?.customer?.lastName) url.searchParams.append('order.lastName', order.customer.lastName);
  if (order?.customer?.email) url.searchParams.append('order.email', order.customer.email);
  if (order?.customer?.phoneNumber) url.searchParams.append('order.phone', order.customer.phoneNumber);
  if (order?.customer?.address1) url.searchParams.append('order.billingAddress.line1', order.customer.address1);
  if (order?.customer?.address2) url.searchParams.append('order.billingAddress.line2', order.customer.address2);
  if (order?.customer?.city) url.searchParams.append('order.billingAddress.city', order.customer.city);
  if (order?.customer?.state) url.searchParams.append('order.billingAddress.state', order.customer.state);
  if (order?.customer?.country) url.searchParams.append('order.billingAddress.country', order.customer.country);
  if (order?.customer?.postalCode) url.searchParams.append('order.billingAddress.postalCode', order.customer.postalCode);
  if (order?.merchantFeeForPaymentPlan) url.searchParams.append('merchantFeeForPaymentPlan', order.merchantFeeForPaymentPlan.toFixed(2));
  if (order?.lineItems) {
    // Trim the descriptions to max 100 chars
    order.lineItems.forEach(lineItem => {
      if (lineItem.description) lineItem.description = lineItem.description.substring(0, 100);
    });
    url.searchParams.append('order.lineItems', JSON.stringify(order.lineItems));
  }

  if (configuration.checkoutFlow) {
    url.searchParams.append('checkoutFlow', configuration.checkoutFlow.toString());

    // Append shipping preferences to Gateway URL.
    updateUrlWithShippingLocations(configuration, url);
  }

  if (configuration.checkoutFlow) url.searchParams.append('checkoutFlow', configuration.checkoutFlow.toString());
  if (configuration.returnToMerchant) url.searchParams.append('returnToMerchant', configuration.returnToMerchant.toString());
  if (configuration.virtualEmbeddedIntegration) url.searchParams.append('metadata.virtualEmbeddedIntegration', configuration.virtualEmbeddedIntegration.toString());
  if (configuration.tokenizerSubdomain) url.searchParams.append('tokenizerSubdomain', configuration.tokenizerSubdomain.toString());

  if (configuration.test) {
    url.searchParams.append('test', configuration.test.toString());
  }

  if (configuration.ephemeralUrl) {
    url.searchParams.append('ephemeralUrl', configuration.ephemeralUrl.toString());
  }

  url.searchParams.append('origin', window.location.origin);
  appendToURLContainingProperties(url, configuration.additionalRiskData);

  const gatewayVirtualAuthorizeUrl = url.href;
  logger.info(`Build gateway virtual authorize URL of '${gatewayVirtualAuthorizeUrl}'`);

  return gatewayVirtualAuthorizeUrl;
}

function appendToURLContainingProperties(url: URL, obj: any, currentPath: string = ''): void {
  for (const key in obj) {
    const value = obj[key];
    const newPath = currentPath ? `${currentPath}.${key}` : key;

    if (value !== null && value !== undefined) {
      if (typeof value === 'object' && !(value instanceof Array)) {
        appendToURLContainingProperties(url, value, newPath);
      } else {
        url.searchParams.append('additionalData.' + newPath, value.toString());
      }
    }
  }
}

// Shipping countries specified by Express merchant that can be shipped to
function updateUrlWithShippingLocations(configuration: CheckoutConfiguration, url: URL) {
  if (configuration.checkoutFlow !== CheckoutFlow.express) {
    return;
  }

  const shippingPreference = configuration?.shippingPreference;
  if (!shippingPreference) {
    return;
  }

  const isAllowedShippingLocationsValid = isShippingLocationsValidAndNotEmpty(shippingPreference.allowedShippingLocations);
  const isDeniedShippingLocationsValid = isShippingLocationsValidAndNotEmpty(shippingPreference.deniedShippingLocations);

  if (isAllowedShippingLocationsValid && isDeniedShippingLocationsValid) {
    logger.warn('Allowed and denied shipping locations both provided. Allowed locations will be used.');
  }

  // Sanitize data
  if (isAllowedShippingLocationsValid) {
    shippingPreference.deniedShippingLocations = [];
  } else if (isDeniedShippingLocationsValid) {
    shippingPreference.allowedShippingLocations = [];
  } else {
    shippingPreference.allowedShippingLocations = [];
    shippingPreference.deniedShippingLocations = [];
  }

  const isDeniedPOBoxShippingLocationsValid = isShippingLocationsValidAndNotEmpty(shippingPreference.deniedPOBoxShippingLocations);

  // Sanitize data
  if (!isDeniedPOBoxShippingLocationsValid) {
    shippingPreference.deniedPOBoxShippingLocations = [];
  }

  // Append to url if shipping locations provided and it can convert to JSON.
  if (shippingPreference.allowedShippingLocations.length > 0 || shippingPreference.deniedShippingLocations.length > 0 || shippingPreference.deniedPOBoxShippingLocations.length > 0) {
    const shippingPreferenceAsJSON = convertShippingPreferenceToJSON(shippingPreference);
    if (shippingPreferenceAsJSON) {
      url.searchParams.append('shippingPreference', shippingPreferenceAsJSON);
    }
  }
}

/**
 * Handle a successful checkout callback message through Zoid as passed from the gateway containing
 * the direct transaction information in the supplied object.
 * @param callback
 */
export async function zoidCallback(callback: VirtualSuccessCallback) {
  // Do not log test card details
  if (!testCardDetails) logger.info('Handling virtual checkout success', callback);
  else {
    const callbackWithoutCard = { ...callback };
    delete callbackWithoutCard.card;
    logger.info('Handling virtual checkout success', callbackWithoutCard);
  }
  // Replace card with test card if necessary
  if (testCardDetails) callback.card = testCardDetails;
  const { card, cardholder, customer } = callback;

  try {
    await onCompleteCallback(callback);
    await onSuccessCallback(card, cardholder, customer);
  } catch (e) {
    logger.error('Failed to handle virtual checkout callback', e);
  }
  await closeCheckout();
}

/**
 * Handles the virtual checkout being closed through Zoid as passed from the gateway.
 * @param data An optional message about why it's closed
 */
async function zoidClosed(data: string) {
  logger.info('Handling virtual checkout close', data);
  try {
    await onCloseCallback(data);
  } catch (e) {
    logger.error('Failed to handle virtual checkout close', e);
  }
  await closeCheckout();
}

async function zoidError(data: string[]) {
  logger.info('Handling virtual checkout error', data);
  try {
    await onErrorCallback(data);
  } catch (e) {
    logger.error('Failed to handle virtual checkout error', e);
  }
  await closeCheckout();
}

/**
 * Create the zoid component responsible for launching the display of the virtual checkout.
 * @param configuration
 * @param checkoutUrl
 */
function createZoidComponent(configuration: CheckoutConfiguration, checkoutUrl: string) {
  // bind opened to zoidComponent for focus and close functionality
  const forceIframe = !!configuration.forceIframe;
  const hideOverlay = !!configuration.hideOverlay;
  const requiresPopup = !configuration.forceIframe && supportsPopups();

  zoidComponent = zoidComponentInit({
    url: checkoutUrl,
    zoidCallback: zoidCallback,
    zoidClosed: zoidClosed,
    zoidError: zoidError,
    bridgeUrl: `${environmentConfiguration.gatewayHost}/virtual/bridge?version=${encodeURIComponent(postRobotVersion)}`,
    forceIframe: forceIframe,
    hideOverlay: hideOverlay,
  });
  // zoid component will be rendered in the <body> (necessary for the overlay)
  const element = 'body';
  zoidComponent.render(element, requiresPopup ? 'popup' : 'iframe').catch(async (e: Error) => {
    // Ignore window closed errors as they are expected
    // https://github.com/krakenjs/zoid/issues/218
    if (e?.message?.toLowerCase() !== 'Window closed'.toLowerCase()) {
      logger.error(e);
    } else {
      logger.debug('Window closed while prerender template is active', e);
    }

    // The zoid closed likely wasn't invoked in the normal close flow (e.g. when the window was force closed)
    await zoidClosed(e.message);
  });

  // overlay and navhelper for popup only
  if (requiresPopup) {
    const $navHelper = document.querySelector('.navhelper');
    if ($navHelper) $navHelper.addEventListener('click', focusCheckout.bind(this));
  }
}

/**
 * Opens a virtual checkout for the specified merchant and order details.
 * @param merchantId The merchant launching the checkout.  Required.
 * @param order The order details of the checkout.  Order amount and merchant reference are required.
 *  Remaining fields are optional but improve the user experience.
 * @param checkoutFlowOrConfiguration
 * @param forceIframe
 * @param hideOverlay
 */
async function openCheckout(merchantId: string, order: Order, checkoutFlowOrConfiguration?: CheckoutFlow | CheckoutConfiguration, forceIframe?: boolean, hideOverlay?: boolean): Promise<void> {
  let configuration: CheckoutConfiguration = {};
  if (typeof checkoutFlowOrConfiguration === 'object') {
    // Type is CheckoutConfiguration
    configuration = checkoutFlowOrConfiguration;
    // returnToMerchant only available in checkoutFLowConfiguration
  } else {
    // Type is CheckoutFlow (Enum)
    configuration.checkoutFlow = checkoutFlowOrConfiguration;
    configuration.forceIframe = forceIframe;
    configuration.hideOverlay = hideOverlay;
  }

  logger.debug('Opening checkout', merchantId, order, configuration);

  const errors = validate(merchantId, order, configuration);
  if (errors && errors.length > 0) {
    if (configuration.virtualEmbeddedIntegration) {
      // use configuration to go to the gateway embedded-redirect route
      window.location.replace(new URL(`${environmentConfiguration.gatewayHost}/virtual/embedded-error-redirect?merchantId=${merchantId}`));
    }

    return;
  }

  const checkoutUrl = buildGatewayUrl(merchantId, order, configuration);
  logger.debug('Gateway URL', checkoutUrl);

  if (configuration.virtualEmbeddedIntegration) {
    window.location.replace(checkoutUrl);
  } else {
    if (zoidComponent) {
      await zoidClosed('Zoid was already loaded');
    }
    createZoidComponent(configuration, checkoutUrl);
  }
}

/**
 * Sets the test card details
 */
function setCardDetails(card?: Card) {
  testCardDetails = card;
}

/**
 * Sets the on success callback handler for the virtual checkout.
 * @param callback
 */
function onSuccess(callback: (card: Card, cardholder: Cardholder, customer: Customer) => Promise<void>): void {
  if (callback) onSuccessCallback = callback;
}

/**
 * Sets the on success callback handler for variable result objects including mfpp and shipping address
 * @param callback
 */
function onComplete(callback: (result?: VirtualSuccessCallback) => Promise<void>): void {
  if (callback) onCompleteCallback = callback;
}

/**
 * Save all the data objects from the onComplete zoid callback
 */
function saveResult(result) {
  if (result) {
    window.sessionStorage.setItem(callbackResult, JSON.stringify(result));
  }
}

/**
 * Clear saved result from zoid callback
 */
function clearResult() {
  window.sessionStorage.removeItem(callbackResult);
}

/**
 * Access all the data objects from the onComplete zoid callback
 */
function getCompleteResult() {
  return (JSON.parse(sessionStorage.getItem(callbackResult)));
}

/**
 * Sets the on error callback handler for the virtual checkout.  This will be invoked in case of
 * any validation errors when opening the checkout.
 */
function onError(callback: (errorMessages: string[]) => Promise<void>): void {
  if (callback) onErrorCallback = callback;
}

/**
 * Sets the on close callback handler for the virtual checkout to handle abandoned checkouts.
 * @param callback
 */
function onClose(callback: (message: string) => Promise<void>): void {
  if (callback) onCloseCallback = callback;
}

/**
 * Focuses on the already launched virtual checkout.  If it is not launched, then any resources are closed and destroyed.
 */
function focusCheckout(): void {
  if (zoidComponent) {
    zoidComponent.focus();
  }
}

/**
 * Closes the checkout if it is open.
 */
async function closeCheckout(): Promise<void> {
  if (zoidComponent) {
    try {
      await zoidComponent.close();
    } catch (e) {
      logger.error('Unable to close virtual checkout', e);
    }
  }
}

/**
 * Validates the supplied parameters.  If they are invalid, the errors are returned
 * in an array and the onError callback is invoked.
 * @param merchantId
 * @param order
 * @param configuration
 */
function validate(merchantId: string, order: Order, configuration?: CheckoutConfiguration): string[] {
  const errors = [];
  if (!merchantId) {
    errors.push('Merchant ID is required');
  }

  if (!uuidRegex.test(merchantId)) {
    errors.push(`Merchant ID '${merchantId}' is not a valid ID`);
  }

  if (!order) {
    errors.push('Order is required');
  } else {
    if (!order.amount || order.amount < 0) {
      errors.push('Order amount is required');
    }

    if (!order.merchantReference) {
      errors.push('Merchant reference is required as merchant\'s identifier for the order');
    }

    if (!order.currency) {
      errors.push('Order currency is required');
    }
  }

  if (configuration?.checkoutFlow) {
    if (!Object.values(CheckoutFlow).includes(configuration.checkoutFlow)) {
      errors.push(`Checkout flow ${configuration.checkoutFlow} is not valid.`);
    }
  }

  if (configuration?.virtualEmbeddedIntegration) {
    if (!isInIframe()) {
      errors.push('Virtual embedded integration flow is not supported unless Zip is loaded from an iframe.');
    }
  }

  if (configuration?.additionalRiskData?.fulfillmentMethod) {
    const fulfillmentMethod = configuration?.additionalRiskData?.fulfillmentMethod;

    if (fulfillmentMethod && fulfillmentMethod.length > 250) {
      errors.push('fulfillmentMethod must be less than 251 characters long');
    }
  }

  if (configuration?.additionalRiskData?.merchantRiskAssessment) {
    const merchantRiskAssessment = configuration?.additionalRiskData?.merchantRiskAssessment;

    if (merchantRiskAssessment && merchantRiskAssessment.length > 250) {
      errors.push('merchantRiskAssessment must be less than 251 characters long');
    }
  }

  let dateError = IsValidUtcDateInThePast('accountCreationDate', configuration?.additionalRiskData?.accountCreationDate ? configuration?.additionalRiskData?.accountCreationDate.toUpperCase() : null);
  if (dateError) errors.push(dateError);
  dateError = IsValidUtcDateInThePast(
    'lastTransactionDate', configuration?.additionalRiskData?.lastTransactionDate ? configuration?.additionalRiskData?.lastTransactionDate.toUpperCase() : null);
  if (dateError) errors.push(dateError);
  if (configuration?.additionalRiskData?.customerAverageOrderValue) {
    const customerAverageOrderValue = configuration?.additionalRiskData?.customerAverageOrderValue;

    // Check if the string has valid numeric format (e.g., "123", "123.45", ".45", "123.")
    const isValidPositiveAmount = isValidAmount(customerAverageOrderValue)[0];

    if (!isValidPositiveAmount) {
      errors.push('customerAverageOrderValue must be a number. Ex 123.45 or 123');
    }
  }

  if (errors.length > 0) {
    logger.warn('Unable to open virtual checkout due to validation errors', errors);

    // send errors to registered callback
    try {
      onErrorCallback(errors).catch((e) => {
        logger.error('Unable to handle validation errors in error callback', e);
      });
    } catch (e) {
      logger.error('Unable to handle validation errors in error callback', e);
    }
  }

  return errors;
}

function IsValidUtcDateInThePast(fieldName, value) {
  if (!value) {
    return;
  }

  const isValidFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/i.test(value);
  if (!isValidFormat) {
    return `${fieldName} is not in the correct format. Required format is YYYY-MM-DDTHH:mm:ssZ ? ${value}`;
  }

  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return `${fieldName} is invalid`;
  }

  const currentDate = new Date();
  // Cater for possibility that client machine time
  // is NOT updated and is up to and hour behind server time
  currentDate.setHours(currentDate.getHours() + 1);

  // Check if the date is in the future
  if (date > currentDate) {
    return `${fieldName} is in the future`;
  }
}

/**
 * Detects if the script is loaded within an iframe based on https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t#answer-326076.
 * The cypress hack is because Cypress makes the self and top windows match themselves.
 * Cypress also doesn't let us check for window.Cypress in iframed windows so we can't easily detect when we're executing in a cypress environment.
 * This is a hack to use an undocumented query string value we can set in our testing.  This is generally low risk as if a merchant were to do this,
 * it would just break their integration in testing anyway.
 * - https://github.com/cypress-io/cypress/issues/2664#issuecomment-434610985
 * = https://github.com/cypress-io/cypress/issues/3864#issuecomment-933303363
 */
function isInIframe(): boolean {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cypressForceIframe = urlParams.get('cypressForceIframe') === 'true';
    return (window.self !== window.top) || cypressForceIframe;
  } catch (e) {
    return true;
  }
}

/**
 * Updates the environment configuration of virtual checkout to use the environment specified
 * @param environmentName The name of the environment to integrate with (e.g. Production, Sandbox, etc.)
 */
function updateEnvironment(environmentName: string): void {
  const currentEnvironment = environmentConfiguration;
  const loadedEnvironment = getConfigurationForEnvironmentName(environmentName);

  if (currentEnvironment?.environmentName !== loadedEnvironment?.environmentName) {
    environmentConfiguration = loadedEnvironment;
  }
}

const virtualCheckoutApi: VirtualCheckoutApi = {
  openCheckout,
  setCardDetails,
  onSuccess,
  onComplete,
  onError,
  onClose,
  focusCheckout,
  closeCheckout,
  updateEnvironment,
  validate,
  getCompleteResult,
  saveResult,
  clearResult,
};

export default virtualCheckoutApi;

/**
 * I like this pattern of exporting internal things to make them more testable.  I don't like that
 * it breaks encapsulation, but unless we rely on some rewiring which has hit-or-miss support,
 * this is the easiest way to test the non-public API.
 *
 * Inspired by:
 * https://stackoverflow.com/questions/54116070/how-to-unit-test-non-exported-functions
 */
export const testables = {
  buildGatewayUrl,
  zoidCallback,
  zoidClosed,
  zoidError,
  createZoidComponent,
  isInIframe,
};