export interface Customer {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
}

export interface Order {
  amount: number
  shippingAmount?: number
  taxAmount?: number
  currency: string
  merchantReference: string
  customer?: Customer
  merchantFeeForPaymentPlan?: number
  lineItems?: LineItem[]
}

export interface LineItem {
  name?: string
  description?: string
  quantity?: number
  price?: number
  sku?: string
  isPreOrder?: boolean
  releaseDate?: Date
}

export interface Address {
  line1: string
  line2: string
  state: string
  country: string
  postalCode: string
  city: string
}

export interface Card {
  number?: string
  cvc?: string
  expirationMonth: number
  expirationYear: number
  brand?: string
  last4?: string
}

export interface Cardholder {
  name: string
  firstName: string
  lastName: string
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
}

/**
 * @deprecated use SuccessCallback instead
 */
export interface Callback {
  customer: Customer
  token: string
  shippingAddress?: Address
  merchantFeeForPaymentPlan?: number
}

export interface VirtualSuccessCallback {
  customer: Customer
  cardholder?: Cardholder
  fiservClientToken?: string
  clientToken?: string
  card?: Card
  shippingAddress?: Address
  merchantFeeForPaymentPlan?: number
  orderId: string
}

export interface ApiSuccessCallback {
  customer: Customer
  order: OrderCallbackInfo
  shippingAddress?: Address
}

export enum CheckoutFlow {
  standard = 'standard',
  express = 'express',
  fiserv = 'fiserv',
  cardSecure = 'cardSecure',
  braintree = 'braintree',
}

export enum EntrySource {
  MerchantButton = 'MerchantButton',
  ZipButton = 'ZipButton',
  SecondChance = 'SecondChance'
}

export interface FiservData {
  clientToken: string
  publicKey: string
}

export interface CardSecureData {
  subdomain: string
}

export interface BraintreeData {
  /** the merchants tokenization key, to create the card token (payment method nonce) for this vc transaction */
  tokenizationKey: string
}

export interface CheckoutConfiguration {
  // Forces the Zip checkout to always be rendered in an iframe and never a pop up
  forceIframe?: boolean

  // Hide the gray overlay on the parent site when checkout is launched
  hideOverlay?: boolean

  // Which UX flow to send the user through
  checkoutFlow?: CheckoutFlow

  // Which source did the VC or api-checkout start from (zip-button, second-chance-widget)
  entrySource?: EntrySource

  // Pass data to parent frame with post message instead of using the Zoid components and callbacks
  virtualEmbeddedIntegration?: boolean

  // Whether VC returns to Merchant Checkout after Zip Checkout (Opposite of autosubmit)
  returnToMerchant?: boolean

  // The subdomain to prepend the API call with for the third party tokenizer gateway
  tokenizerSubdomain?: string

  /// <summary>
  /// Gets or sets the shipping preferences for the merchant.
  /// </summary>
  shippingPreference?: ShippingPreference

  // If this a test or not.
  test?: boolean

  /// <summary>
  /// Additional Data Fields are optional info fields from merchant
  /// </summary>
  additionalRiskData?: AdditionalRiskData

  /// <summary>
  /// The URL to use Ephemeral Environment URL
  /// </summary>
  ephemeralUrl?: string
}

export interface ShippingLocation {
  // The country of the shipping location (US, CA)
  country?: string

  // The state/province of the shipping country (AK, NY)
  state?: string
}

/// <summary>
/// Additional Data Fields are optional info fields from merchant
/// </summary>
export interface AdditionalRiskData {
  /// <summary>
  /// The date of the customer's last transaction on a merchant's site.
  /// </summary>
  lastTransactionDate?: string

  /// <summary>
  /// The date the customer's account was created on a merchant's site.
  /// </summary>
  accountCreationDate?: string

  /// <summary>
  /// Indicates whether the customer is currently logged on a merchant's site.
  /// </summary>
  customerLoggedIn?: string

  /// <summary>
  /// Indicates whether the customer is a member of the merchant's site.
  /// </summary>
  customerIsMember?: string

  /// <summary>
  /// The risk assessment score for the merchant.
  /// </summary>
  merchantRiskAssessment?: string

  /// <summary>
  /// Indicates whether the transaction was performed from the same device.
  /// </summary>
  sameDevice?: string

  /// <summary>
  /// The method used to fulfill the order on a merchant's site.
  /// </summary>
  fulfillmentMethod?: string

  /// <summary>
  /// The total number of transactions made by the customer on a merchant's site.
  /// </summary>
  customerTransactionCount?: string

  /// <summary>
  /// The average order value of the customer's transactions on a merchant's site.
  /// Example format: "123.45" or "123"
  /// </summary>
  customerAverageOrderValue?: string

  /// <summary>
  /// Indicates whether the customer has previous fraud incidents.
  /// </summary>
  customerFraudIncidents?: string
}

export interface ShippingPreference {
  /// <summary>
  /// List of states/countries the merchant will ship to.
  /// </summary>
  allowedShippingLocations?: ShippingLocation[]

  /// <summary>
  /// List of states/countries the merchant will not ship to.
  /// </summary>
  deniedShippingLocations?: ShippingLocation[]

  /// <summary>
  /// List of states/countries with PO Boxes the merchant will not ship to
  /// </summary>
  deniedPOBoxShippingLocations?: ShippingLocation[]
}

export interface ApiCheckoutConfiguration {
  hideOverlay?: boolean
}

export interface CheckoutOptions {
  checkoutFlow?: string
  orderId?: string
  merchantId: string
  merchantReference: string
  order?: Order
  test?: boolean
  capture?: boolean
  callbackUrl: string
  metadata?: Object
  merchantFeeForPaymentPlan?: number
  brandName?: string
}

export interface OrderCallbackInfo {
  timestamp: string
  orderId: string
  isSuccess: boolean
  isTest: boolean
  merchantId: string
  merchantReference: string
  amount: number
  currency: string
}
